import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

const CRegister = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        cellNo: '',
        passportId: '',
        nationality: '',
        gender: '',
        profession: '',
        registrationPurpose: '',
        companyBusinessName: '',
        businessType: '',
        tradeLicenseNumber: '',
        email: '',
        street: '',
        companyAddress: '',
        countryOfReg: '',
        state: '',
        file1: null,
        file2: null,
        file3: null,
    });

    const navigate = useNavigate();

    const handleLandingPage = () => {
        navigate('/');
    };

    const handleCRegister = () => {
        navigate('/cregister');
    };

    const handleSrpRegister = () => {
        navigate('/srpregister');
    };

    const handleIndRegister = () => {
        navigate('/indregister');
    };

    const handleInvestorRegister = () => {
        navigate('/investregister');
    };

    const handleTC = (e) => {
        e.preventDefault();
        setShowTerms(true);
        setShowForm(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!formData.cellNo) newErrors.cellNo = true;
        if (!formData.email) newErrors.email = true;
        if (!formData.passportId) newErrors.passportId = true;
        if (!formData.nationality) newErrors.nationality = true;
        if (!formData.gender) newErrors.gender = true;
        if (!formData.profession) newErrors.profession = true;
        if (!formData.companyBusinessName) newErrors.companyBusinessName = true;
        if (!formData.businessType) newErrors.businessType = true;
        if (!formData.tradeLicenseNumber) newErrors.tradeLicenseNumber = true;
        if (!formData.street) newErrors.street = true;
        if (!formData.countryOfReg) newErrors.countryOfReg = true;
        if (!formData.state) newErrors.state = true;
        if (!formData.file1) newErrors.file1 = true;
        if (!formData.file2) newErrors.file2 = true;
        if (!formData.file3) newErrors.file3 = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formIsValid = validateForm();
        if (formIsValid) {
            handleTC();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    
        // Remove the error for this field if it is filled
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
        
    };
    
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0], // Store the first file in the input
        });
    };

    return (
        <>
            <div className="header">
                <button className="btnicon">
                    <div className="logo2" onClick={handleLandingPage}>
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2"><img src="images/Setting.png" alt="Settings" /></button>
                    <button className="phone2"><img src="images/Phone.png" alt="Phone" /></button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">
                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndRegister}>
                                <img src="images/personalInfo.png" height="25" width="25" alt="Personal Info" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" height="25" width="25" alt="Company Registration" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorRegister}>
                                <img src="images/InvestorsIcon.png" height="25" width="25" alt="Investors" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleSrpRegister}>
                                <img src="images/SalesPartnerIcon.png" height="25" width="25" alt="Sales Partner" />
                            </button>
                        </div>

                        {showForm && (
                            <div className="loginrightside">
                                <div className="loginformtitle">Company Registration</div>
                                <div className="editinfo">
                                    <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" /></button>
                                </div>
                                <div className="formdiv">
                                    <form onSubmit={handleSubmit}>
                                        <input 
                                            type="text" 
                                            name="firstName"
                                            className={`inputbg4 ${errors.firstName ? 'error-border' : ''}`} 
                                            placeholder="First Name" 
                                            value={formData.firstName}
                                            onChange={handleInputChange}
                                        />
                                        
                                        <input 
                                            type="text" 
                                            name="middleName" 
                                            className="inputbg4" 
                                            placeholder="Middle Name"  
                                            value={formData.middleName}
                                            onChange={handleInputChange}
                                        />
                                        
                                        <input 
                                            type="text" 
                                            name="lastName" 
                                            className={`inputbg5 ${errors.lastName ? 'error-border' : ''}`} 
                                            placeholder="Last Name" 
                                            value={formData.lastName}
                                            onChange={handleInputChange}
                                        />

                                        <input
                                            type="tel"
                                            name="cellNo"
                                            className={`inputbg4 ${errors.cellNo ? 'error-border' : ''}`}
                                            placeholder="Cell No"
                                            value={formData.cellNo}
                                            onChange={handleInputChange}
                                        />

                                        <input
                                            type="text"
                                            name="passportId"
                                            className={`inputbg4 ${errors.passportId ? 'error-border' : ''}`}
                                            placeholder="Passport/ID No"
                                            value={formData.passportId}
                                            onChange={handleInputChange}
                                        />

                                        <input
                                            type="text"
                                            name="nationality"
                                            className={`inputbg5 ${errors.nationality ? 'error-border' : ''}`}
                                            placeholder="Nationality"
                                            value={formData.nationality}
                                            onChange={handleInputChange}
                                        />

<select 
                                    name="gender" 
                                    className={`inputbg4 ${errors.gender ? 'error-border' : ''}`} 
                                    value={formData.gender} 
                                    onChange={handleInputChange}
                                >
                                    <option value="">Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>

                                        <input
                                            type="text"
                                            name="profession"
                                            className={`inputbg4 ${errors.profession ? 'error-border' : ''}`}
                                            placeholder="Profession"
                                            value={formData.profession}
                                            onChange={handleInputChange}
                                        />

                                        <select
                                            name="registrationPurpose"
                                            className={`select1 ${errors.registrationPurpose ? 'error-border' : ''}`}
                                            value={formData.registrationPurpose}
                                            onChange={handleInputChange}
                                        >
                                            <option>Registration Purpose</option>
                                            <option style={{ color: 'black' }}>Option 1</option>
                                            <option style={{ color: 'black' }}>Option 2</option>
                                        </select>

                                        <input
                                            type="text"
                                           
                                            name="companyBusinessName"
                                            className={`inputbg6 ${errors.companyBusinessName ? 'error-border' : ''}`}
                                            placeholder="Company Business Name"
                                            value={formData.companyBusinessName}
                                            onChange={handleInputChange}
                                        />

                                        <select
                                            name="businessType"
                                            className={`select2 ${errors.businessType ? 'error-border' : ''}`}
                                            value={formData.businessType}
                                            onChange={handleInputChange}
                                        >
                                            <option>Business Type</option>
                                            <option style={{ color: 'black' }}>Option 1</option>
                                            <option style={{ color: 'black' }}>Option 2</option>
                                        </select>

                                        <input
                                            type="text"
                                            style={{width:'49%'}}
                                            name="tradeLicenseNumber"
                                            className={`inputbg6 ${errors.tradeLicenseNumber ? 'error-border' : ''}`}
                                            placeholder="Trade License Number"
                                            value={formData.tradeLicenseNumber}
                                            onChange={handleInputChange}
                                        />

                                        <input
                                            type="email"
                                            name="email"
                                            className={`inputbg7 ${errors.email ? 'error-border' : ''}`}
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />

                                        <input
                                            type="text"
                                            name="street"
                                            className={`inputbg8 ${errors.street ? 'error-border' : ''}`}
                                            placeholder="Street"
                                            value={formData.street}
                                            onChange={handleInputChange}
                                        />

                                        <select
                                            name="countryOfReg"
                                            className={`inputbg8 ${errors.countryOfReg ? 'error-border' : ''}`}
                                            value={formData.countryOfReg}
                                            onChange={handleInputChange}
                                        >
                                            <option>Country of Reg</option>
                                            <option style={{ color: 'black' }}>Option 2</option>
                                        </select>

                                        <select
                                            name="state"
                                            className={`inputbg8 ${errors.state ? 'error-border' : ''}`}
                                            value={formData.state}
                                            onChange={handleInputChange}
                                        >
                                            <option>State</option>
                                            <option style={{ color: 'black' }}>Option 2</option>
                                        </select>

                                        <div className={`fileleft ${errors.file1 ? 'error-border' : ''}`}>
                                            <input type="file" className="file1" name="file1" onChange={handleFileChange} />
                                            Click Here
                                        </div>
                                        <div className={`fileleft2 ${errors.file2 ? 'error-border' : ''}`}>
                                            <input type="file" className="file2" name="file2" onChange={handleFileChange}  />
                                            Click Here
                                        </div>
                                        <div className={`fileleft2 ${errors.file3 ? 'error-border' : ''}`}>
                                            <input type="file" className="file3" name="file3" onChange={handleFileChange} />
                                            Click Here
                                        </div>

                                        <div className="clicknext">
                                            <button type="submit">Click Next to Proceed* <img src="images/ClickNextToProced.png" alt="Click Next" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}

                        {showTerms && (
                            <div className="loginrightside">
                                <div className="loginformtitle">Company Registration</div>
                                <div className="editinfo">
                                    <button>Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" /></button>
                                </div>
                                <div className="formdiv">
                                    <div className="companytermheading">Terms & Conditions Agreement*</div>
                                    <div className="companytext">
                                        <p>Lorem Ipsum is simply dummy text...</p>
                                        <p>It was popularised in the 1960s...</p>
                                        <p>Lorem Ipsum is simply dummy text...</p>
                                    </div>

                                    <div className="termagree">
                                        <label>
                                            <input type="checkbox" /> I Agree with Terms & Conditions*
                                        </label>
                                    </div>

                                    <div>
                                        <button className="sub1">Submit</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint" /></button>
                        <button><img src="images/Update.png" alt="Update" /></button>
                        <button><img src="images/Tiktok.png" alt="Tiktok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CRegister;

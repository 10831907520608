import React from "react";
import { useNavigate } from "react-router-dom";
const VehicleRegistration = () => {
  const  navigate =useNavigate()
  const handlevehsub =() =>{
    navigate('/vehsub')
  }
  return (
    <div className="header">
      <div className="logo2">
        <img src="images/BusinessWindow.png" alt="Business Window" />
      </div>
      <div className="topicons">
        <button className="setting2">
          <img src="images/Setting.png" alt="Settings" />
        </button>
        <button className="phone2">
          <img src="images/Phone.png" alt="Phone" />
        </button>
      </div>

      <div className="width1">
        <div className="maindiv">
          <div className="searchbar2">
            <form>
              <input type="submit" className="submitbtn" />
              <input
                type="text"
                placeholder="Search here Business Windows"
                className="searchfield"
              />
              <input type="button" className="voicebtn" />
            </form>
          </div>

          <div className="glassdiv2">
            <div className="topdiv">
              <div className="vehloginformtitle">Vehicle Registration System</div>
              <div className="editinfo2">
                <button>
                  Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" />
                </button>
              </div>
            </div>

            <div className="loginleftside2">
              <div className="loginleftsidetext">Select Field</div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon .png"
                    height="15"
                    width="15"
                    alt="Login Icon"
                  />
                </button>
                Personal Info
              </div>
            
                  <div className="infobtn">
                    <button className="loginleftsidebtn3">
                      <img
                        src="images/Login Icon .png"
                        height="15"
                        width="15"
                        alt="Login Icon"
                      />
                    </button>
                    
                  </div>
                
            </div>

            <div className="loginrightside2">
              <div className="formdiv1">
                <select className="inputbg11">
                  <option>Engine Fuel Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Engine Power</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <input
                  type="text"
                  className="inputbg11"
                  placeholder="Transmission No"
                />

                <select className="inputbg11">
                  <option>Transmission Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Transmission Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Transmission Power</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Lights Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Suspension Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Interior Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Tires Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Computer Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Steering Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Upholstery Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Air Bags Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Air Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Heating Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Glasses Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Exhaust Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Rusting Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Electrical Systems</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>History Maintained</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Vehicle Loan</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Dashboard Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Overhauled Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <div className="fileleft" style={{ marginLeft: "7px" }}>
                  <input
                    type="file"
                    className="file1"
                    placeholder="Vehicle Pictures"
                    required
                  />
                  Click Here
                </div>
                <div className="fileleft2">
                  <input
                    type="file"
                    className="file2"
                    placeholder="Vehicle License"
                    required
                  />
                  Click Here
                </div>
                <div className="fileleft2">
                  <input
                    type="file"
                    className="file3"
                    placeholder="Owner ID"
                    required
                  />
                  Click Here
                </div>
                <div className="fileleft2">
                  <input
                    type="file"
                    className="file3"
                    placeholder="Trade Copy"
                    required
                  />
                  Click Here
                </div>

                <div className="clicknext" style={{ marginTop: "15px" }}>
                  <button onClick={handlevehsub}>
                    Click Next to Proceed{" "}
                    <img
                      src="images/ClickNextToProced.png"
                      alt="Next to Proceed"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="icondiv2" style={{ marginTop: "15px" }} >
            {[
              "Support",
              "Helpline",
              "Complaint",
              "Update",
              "Tiktok",
              "FB",
              "YOutube",
              "Whatsapp",
              "Twitter",
              "Insta",
              "Linkedin",
              "Pinterest",
              "Google Plus",
              "Reddit",
            ].map((icon, index) => (
              <button key={index}>
                <img src={`images/${icon}.png`} alt={icon} />
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleRegistration;

import React from "react";
import { useNavigate } from "react-router-dom";

const Message = () => {
    const navigate = useNavigate();

    const handleLandingPage = () => {
        navigate('/');
    };

    return (
        <>
            {/* Header Section */}
            <div className="header">
                <button className="btnicon" onClick={handleLandingPage}>
                    <div className="logo2">
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>

                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone" />
                    </button>
                </div>
            </div>

            {/* Main Content Section */}
            <div className="width1">
                <div className="maindiv">
                    {/* Search Bar */}
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    {/* Message Box */}
                    <div className="glassdiv3">
                        <div className="innerbox">Important Message</div>
                        <div className="innerbox2">
                            Thank you very much for your application.
                            <br />
                            Your request reference number has been generated.
                            You will receive account approval on the given email.
                            <br />
                            Please keep checking your email for further processing.
                            <br />
                            In case you want to inquire further, call our helpline or
                            visit our service center.
                            <br />
                            Please follow us on social media below for more updates.
                        </div>
                    </div>

                    {/* Social Media Icons */}
                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint" /></button>
                        <button><img src="images/Update.png" alt="Update" /></button>
                        <button><img src="images/Tiktok.png" alt="Tiktok" /></button>
                        <button><img src="images/FB.png" alt="Facebook" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter" /></button>
                        <button><img src="images/Insta.png" alt="Instagram" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};
    
export default Message;

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignIn from './Pages/auth/Signin';
import Main from './Pages/Main';
import Reset from './Pages/Reset';
import ResetPassword from './Pages/ResetPassword';
import SPRegister from './Pages/SPRegister';
import TermandCondition from './Pages/TermandCondition';
import CRegister from './Pages/CRegister';
import Message from './Pages/Message';
import IndRegister from './Pages/IndRegister';
import InvestorRegister from './Pages/InvestorRegister';
import EmployeInfo from './Pages/Employe';
import EmployeEducation from './Pages/EmployeEducation';
import Skill from './Pages/skill';
import EmployeExper from './Pages/EmployeExper';
import Franchise from './Pages/Franchie';
import FranchiseInfo from './Pages/Franchiesinfo';
import FranchiesSubmit from './Pages/FranchiesSubmit';
import FrenBussinesinfo from './Pages/FrenBussinesinfo';
import Minicontrolpanerl from './Pages/Minicontrolpanel'
import UserListPage from './Pages/userlistings'
import Vehical from './Pages/vehical';
import VehicleSubmit from './Pages/Vehicalsubmit';
import VehicleRegistration from './Pages/VehicalRegistration';
import VehicalCompartment from './Pages/VehicalCompartment';
import VehicalNewServiceCode from './Pages/VehicalNewServiceCode';
import Superv from './Pages/SuperAdmi';
import Register from './Pages/auth/Register';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/super" element={<Superv/>} />
        <Route path="/veh" element={<Vehical/>}/>
        <Route path="/signin" element={<SignIn/>}/>
        <Route path='/rpassword' element={<ResetPassword/>}/>
        <Route path='/reset' element={<Reset/>}/>
        <Route path='/srpregister' element ={<SPRegister/>}/>
        <Route path = '/term' element = {<TermandCondition/>}/>
        <Route path='/cregister' element ={<CRegister/>}/>
        <Route path='/message' element = {< Message/>}/>
        <Route path='/indregister' element = {< IndRegister/>}/>
        <Route path='/investregister' element = {< InvestorRegister/>}/>
        <Route path='/employeinfo' element = {<EmployeInfo/>}/>
        <Route path= '/empedu' element = {<EmployeEducation/>}/>
        <Route path= '/skill'element= {<Skill/>}/>
        <Route path= '/empexp'element= {<EmployeExper/>}/>
        <Route path='/vehicalRegister' element = {<VehicleRegistration/>}/> 
        <Route path='/franchies' element = {<Franchise/>}/>
        <Route path='/franchiesinfo' element = {<FranchiseInfo/>}/>
        <Route path='/frachiessubmit' element ={<FranchiesSubmit/>}/>
        <Route path='/frenchbussinesinfo' element={<FrenBussinesinfo/>}/>

        <Route path='/cp' element={<Minicontrolpanerl/>}/>
        <Route path='/users' element={<UserListPage/>}/>

        <Route path='/vehcamp'element={<VehicalCompartment/>}/>
        <Route path='/vehsub' element={<VehicleSubmit/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/ControlPanel.css'; // Adjust this path according to your folder structure

const UserListPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('access_token'); // Get the token

            try {
                const response = await axios.get('http://127.0.0.1:8000/api/users', {
                    headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the headers
                    },
                });
                setUsers(response.data); // Set the users data in state
            } catch (error) {
                console.error('Error fetching users:', error);
                // Handle unauthorized access or any errors
                if (error.response && error.response.status === 401) {
                    navigate('/singin'); // Redirect to login if unauthorized
                }
            } finally {
                setLoading(false); // Set loading to false after the request is completed
            }
        };

        fetchUsers();
    }, [navigate]); // Dependency array includes navigate

    const handleMainPage = () => {
        navigate('/'); // Navigate to main page
    };

    if (loading) {
        return <div>Loading...</div>; // Show loading state
    }

    return (
        <div className="container">
            <div className="header">
                <button className="btnicon" onClick={handleMainPage}>
                    <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                </button>
                <h1>User List</h1>
            </div>
            <div className="user-list">
            <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>User Type</th>
                            <th>Role</th>
                            <th>Permissions</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{`${user.name} ${user.middle_name || ''} ${user.last_name || ''}`}</td>
                                <td>{user.email}</td>
                                <td>{user.user_type}</td>
                                <td>{user.role ? user.role.name : 'N/A'}</td>
                                <td>
                                    {user.role && user.role.permissions.length > 0 ? (
                                        user.role.permissions.map(permission => (
                                            <span key={permission.id} className="badge bg-primary me-1">
                                                {permission.name}
                                            </span>
                                        ))
                                    ) : (
                                        <span className="text-muted">No Permissions</span>
                                    )}
                                </td>
                                <td>{user.status || 'Active'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserListPage;

import React, { useState,useEffect, useRef  } from "react";

const Superv = () => {
    // State to manage the visibility of lightbox1
    const [isLightboxVisible, setIsLightboxVisible] = useState(false);
    const [isLightboxVisible1, setIsLightboxVisible1] = useState(false);
    const [isLightboxVisible2, setIsLightboxVisible2] = useState(false);
    const [isLightboxVisible3, setIsLightboxVisible3] = useState(false);

    // Function to toggle the visibility
    const toggleLightbox = () => {
        setIsLightboxVisible(!isLightboxVisible);
    };

    const toggleLightbox1 = () => {
        setIsLightboxVisible1(!isLightboxVisible1);
    };
    const toggleTopIcon = () => {
        setIsLightboxVisible2(!isLightboxVisible2);
    };

    const toggleleftIcon = () => {
        setIsLightboxVisible3(!isLightboxVisible3);
    };
    const lightboxRef = useRef(null); // Reference for the popup container
    const lightbox1Ref = useRef(null);
    const lightbox2Ref = useRef(null);
    const lightbox3Ref = useRef(null);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (lightboxRef.current && !lightboxRef.current.contains(event.target)) {
                setIsLightboxVisible(false);
            }
            if (lightbox1Ref.current && !lightbox1Ref.current.contains(event.target)) {
                setIsLightboxVisible1(false);
            }
            if (lightbox2Ref.current && !lightbox2Ref.current.contains(event.target)) {
                setIsLightboxVisible2(false);
            }
            if (lightbox3Ref.current && !lightbox3Ref.current.contains(event.target)) {
                setIsLightboxVisible3(false);
            }
        };

        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup event listener
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);






    return (
        <>






            <div className="suppermainbox">
                <div className="supperinnderdiv">

                    <div className="supersettingicon">
                        <button><img src="images/Setting.png" /></button>
                        <button><img src="images/Setting.png" /></button>
                        <button><img src="images/Setting.png" /></button>
                        <button><img src="images/Setting.png" /></button>
                    </div>
                    {!isLightboxVisible2 && (
                        <div ref={lightboxRef}  className="tsupperbottomicondiv">
                            <button onClick={toggleTopIcon}>
                                <img src="images/Setting.png" height="15" width="15" />
                                <span> Setting</span>
                            </button>
                        </div>
                    )}

                    {/* Popup with buttons */}
                    {isLightboxVisible2 && (
                        <div ref={lightbox2Ref}  className="suppertopicondiv">
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 1</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 2</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 3</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 4</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 5</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 6</span></button>

                            {/* Add a close button to hide the popup */}
                            <button onClick={toggleTopIcon} className="close-popup">
                                Close Popup
                            </button>
                        </div>
                    )}

                    <div className="supersettingicon2">
                        <button><img src="images/Setting.png" /></button>
                        <button><img src="images/Setting.png" /></button>
                        <button><img src="images/Setting.png" /></button>
                        <button><img src="images/Setting.png" /></button>
                    </div>

                    <div className="supperbgbox">

                        <div className="superdivbox1">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>

                        <div className="superdivbox2">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>


                        <div className="superdivbox3">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>

                        <div className="superdivbox3">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>

                        <div className="superdivbox3">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>
                        <div className="superdivbox3">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>


                        <div className="superdivbox4">

                            <button className="superbox1">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox2">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>
                            <button className="superbox3">
                                <img src="images/offer.png" />
                                <span>Hot Offers</span>
                            </button>

                        </div>

                    </div>


                    <div className="superstart">
                        <button onClick={toggleLightbox} >Start</button>
                    </div>
                    <div className="supperbottomicondiv">
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                        <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting</span></button>
                    </div>

                    <div className="supperbottomright">
                        <button onClick={toggleLightbox1} className="supperbottomrightbtn"></button>
                        <button className="supperbottomrightbtn"></button>
                        <button className="supperbottomrightbtn"></button>
                        <button className="supperbottomrightbtn"></button>
                    </div>
                    {isLightboxVisible && (
                        <div ref={lightboxRef}  className="lightbox1">
                            <div className="supperwidth3">
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                            </div>
                            <div className="supperwidth2">
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                                <button className="superbtn3"></button>
                            </div>

                            <div className="supperwidth1">
                                <button className="superbtn1"></button>
                                <button className="superbtn1"></button>
                                <button className="superbtn1"></button>
                                <button className="superbtn1"></button>
                                <button className="superbtn2"></button>
                                <button className="superbtn2"></button>
                                <button className="superbtn1"></button>
                                <button className="superbtn1"></button>
                                <button className="superbtn1"></button>
                                <button className="superbtn1"></button>
                            </div>
                        </div>
                    )}

                    {isLightboxVisible1 && (
                        <div ref={lightbox1Ref} className="lightbox2 show">
                            {/* Top section with Home Button and Icons */}
                            <div className="mainsidebar">
                                <button className="mainhomeBtn"></button>
                                <div className="maintopIcons">
                                    <button className="mainiconBtn"></button>
                                    <button className="mainiconBtn"></button>
                                    <button className="mainiconBtn"></button>
                                    <button className="mainiconBtn"></button>
                                    <button className="mainiconBtn"></button>
                                </div>
                            </div>

                            {/* Main Content Area */}
                            <div className="mainContent">
                                {/* Larger Grid Section on the Right */}
                                <div className="mainfront">
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                </div>

                                {/* Smaller Grid Section on the Left */}
                                <div className="mainsidebutton">
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                    <div className="maingridItem"></div>
                                </div>
                            </div>
                        </div>
                    )}



                </div>

                {!isLightboxVisible3 && (
                        <div className="tsupperrighticondiv">
                            <button onClick={toggleleftIcon}>
                                <img src="images/Setting.png" height="15" width="15" />
                                <span> Setting</span>
                            </button>
                        </div>
                    )}

                    {/* Popup with buttons */}
                    {isLightboxVisible3 && (
                        <div ref={lightbox3Ref} className="supperrighticondiv">
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 1</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 2</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 3</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 4</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 5</span></button>
                            <button><img src="images/Setting.png" height="15" width="15" /> <span> Setting 6</span></button>

                            {/* Add a close button to hide the popup */}
                            <button onClick={toggleTopIcon} className="close-popup">
                                Close Popup
                            </button>
                        </div>
                    )}


            </div>



        </>
    )
}

export default Superv
import React, { useState } from "react";
import '../styles/CompanyRegister.css';
import '../styles/styles.css';
import { useNavigate } from 'react-router-dom';

const SPRegister = () => {
    const [showTerms, setShowTerms] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        cellNo: '',
        passportId: '',
        nationality: '',
        gender: '',
        companyName: '',
        businessType: '',
        officeAddress: '',
        email: '',
        postalCode: ''
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Remove the error for this field if it is filled
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!formData.cellNo) newErrors.cellNo = true;
        if (!formData.passportId) newErrors.passportId = true;
        if (!formData.nationality) newErrors.nationality = true;
        if (!formData.gender) newErrors.gender = true;
        if (!formData.companyName) newErrors.companyName = true;
        if (!formData.businessType) newErrors.businessType = true;
        if (!formData.officeAddress) newErrors.officeAddress = true;
        if (!formData.email) newErrors.email = true;
        if (!formData.postalCode) newErrors.postalCode = true;

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setShowTerms(true);
            setShowForm(false);
        }
    };

    const handleTC = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setShowTerms(true);
            setShowForm(false);
        }
    };

    const handleLandingPage = () => {
        navigate('/');
    };

    const handleCRegister = () => {
        navigate('/cregister');
    };

    const handleSrpRegister = () => {
        navigate('/srpregister');
    };

    const handleIndRegister = () => {
        navigate('/indregister');
    };

    const handleInvestorRegister = () => {
        navigate('/investregister');
    };

    const handleEditInfo = () => {
        setShowTerms(false);
        setShowForm(true);
    };

    return (
        <>
            <div className="header">
                <button className="btnicon">
                    <div className="logo2" onClick={handleLandingPage}>
                        <img src="images/BusinessWindow.png" alt="Business Window" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2"><img src="images/Setting.png" alt="Settings" /></button>
                    <button className="phone2"><img src="images/Phone.png" alt="Phone" /></button>
                </div>
            </div>
            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>
                    <div className="glassdiv2">
                        <div className="loginleftside">
                            <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndRegister}>
                                <img src="images/personalInfo.png" height="25" width="25" alt="Personal Info" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" height="25" width="25" alt="Company Registration" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorRegister}>
                                <img src="images/InvestorsIcon.png" height="25" width="25" alt="Investors" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleSrpRegister}>
                                <img src="images/SalesPartnerIcon.png" height="25" width="25" alt="Sales Partner" />
                            </button>
                        </div>
                        <div className="loginrightside">
                            <div className="loginformtitle">Sales Partner Registration</div>
                            <div className="formdiv">
                                {showForm && (
                                    <form onSubmit={handleSubmit}>
                                        <input 
                                            type="text" 
                                            name="firstName" 
                                            className={`sprinputbg4 ${errors.firstName ? 'error-border' : ''}`} 
                                            placeholder="First Name*" 
                                            value={formData.firstName} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="middleName" 
                                            className="sprinputbg4" 
                                            placeholder="Middle Name" 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="lastName" 
                                            className={`sprinputbg5 ${errors.lastName ? 'error-border' : ''}`} 
                                            placeholder="Last Name" 
                                            value={formData.lastName} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="cellNo" 
                                            className={`sprinputbg4 ${errors.cellNo ? 'error-border' : ''}`} 
                                            placeholder="Cell No" 
                                            value={formData.cellNo} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="passportId" 
                                            className={`sprinputbg4 ${errors.passportId ? 'error-border' : ''}`} 
                                            placeholder="Passport/ID No" 
                                            value={formData.passportId} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="nationality" 
                                            className={`sprinputbg5 ${errors.nationality ? 'error-border' : ''}`} 
                                            placeholder="Nationality" 
                                            value={formData.nationality} 
                                            onChange={handleInputChange} 
                                        />
                                        <select 
                                            name="gender" 
                                            className={`inputbg4 ${errors.gender ? 'error-border' : ''}`} 
                                            value={formData.gender} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        <input
                                            type="text"
                                            name="profession"
                                            className={`inputbg4 ${errors.profession ? 'error-border' : ''}`}
                                            placeholder="Profession"
                                            value={formData.profession}
                                            onChange={handleInputChange}
                                        />
                                        <select
                                            name="registrationPurpose"
                                            className={`select1 ${errors.registrationPurpose ? 'error-border' : ''}`}
                                            value={formData.registrationPurpose}
                                            onChange={handleInputChange}
                                        >
                                            <option>Registration Purpose</option>
                                            <option style={{ color: 'black' }}>Option 1</option>
                                            <option style={{ color: 'black' }}>Option 2</option>
                                        </select>
                                        <input 
                                            type="text" 
                                            name="companyName" 
                                            className={`sprinputbg6 ${errors.companyName ? 'error-border' : ''}`} 
                                            placeholder="Company Name" 
                                            value={formData.companyName} 
                                            onChange={handleInputChange} 
                                        />
                                        <select 
                                            name="businessType" 
                                            className={`sprinputbg7 ${errors.businessType ? 'error-border' : ''}`} 
                                            value={formData.businessType} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Business Type</option>
                                            <option value="Type 1">Type 1</option>
                                            <option value="Type 2">Type 2</option>
                                        </select>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            className={`sprinputbg7 ${errors.email ? 'error-border' : ''}`} 
                                            placeholder="Email Address" 
                                            value={formData.email} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="officeAddress" 
                                            className={`sprinputbg7 ${errors.officeAddress ? 'error-border' : ''}`} 
                                            placeholder="Office Address" 
                                            value={formData.officeAddress} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="postalCode" 
                                            className={`sprinputbg7 ${errors.postalCode ? 'error-border' : ''}`} 
                                            placeholder="Postal Code" 
                                            value={formData.postalCode} 
                                            onChange={handleInputChange} 
                                        />
                                        <div className="clicknext">
                                            <button onClick={handleTC} type="submit">Click Next to Proceed*</button>
                                        </div>
                                    </form>
                                )}
                                {showTerms && (
                                    <div className="terms-container">
                                        <div className="companytermheading">Terms & Conditions Agreement*</div>
                                        <div className="companytext">
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                            <p>It was popularised in the 1960s with the release of Letraset sheets...</p>
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                        </div>
                                        <div className="termagree">
                                            <label>
                                                <input type="checkbox" /> I Agree with Terms & Conditions*
                                            </label>
                                        </div>
                                        <div>
                                            <button className="sub1" onClick={handleEditInfo}>Edit Info</button>
                                            <button className="sub1" >Submit</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SPRegister;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const IndRegister = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        cellNo: '',
        passportIdNo: '',
        nationality: '',
        gender: '',
        profession: '',
        registrationPurpose: '',
        email: '',
        floorNo: '',
        flatNo: '',
        street: '',
        residentialAddress: '',
        stayCountry: '',
        state: '',
        postalCode: '',
        termsAgreed: false,
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };
    const handlelandingpage = (e) => {
        navigate('/')
    }

    const handleCRegister = (e) => {
        navigate('/cregister')
    }

    const handlesrpregister = (e) => {
        navigate('/srpregister')
    }
    const handleIndregister = (e) => {
        navigate('/indregister')
    }
    const handleInvestorregistor = (e) => {
        navigate('/investregister')
    }

    const handleemployeinfo = (e) =>{
        navigate('/employeinfo')
    }

    const validateForm = () => {
        let formErrors = {};

        // Check required fields
        if (!formData.firstName) {
            formErrors.firstName = "This field is required";
        }

        if (!formData.lastName) {
            formErrors.lastName = "This field is required";
        }

        if (!formData.gender) {
            formErrors.gender = "This field is required";
        }

        if (!formData.nationality) {
            formErrors.nationality = "This field is required";
        }

        // Validate email format
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Please enter a valid email address";
        }

        // Validate cell number (only digits)
        if (!formData.cellNo || !/^\d+$/.test(formData.cellNo)) {
            formErrors.cellNo = "Please enter a valid cell number";
        }

        if (!formData.residentialAddress) {
            formErrors.residentialAddress = "This field is required";
        }

        if (!formData.termsAgreed) {
            formErrors.termsAgreed = "You must agree to the terms and conditions";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            navigate('/message');
        }
    };

    return (
        <>
            <div className="header">
                <button className='btnicon' onClick={() => navigate('/')}>
                    <div className="logo2">
                        <img src="images/BusinessWindow.png" alt="Business Window Logo" />
                        
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2">
                        <img src="images/Setting.png" alt="Settings Icon" />
                    </button>
                    <button className="phone2">
                        <img src="images/Phone.png" alt="Phone Icon" />
                    </button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">
                        <div className="loginleftside">
                        <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndregister}>
                                <img src="images/personalInfo.png" alt="Personal Info Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" alt="Company Registration Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorregistor}>
                                <img src="images/InvestorsIcon.png" alt="Investors Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handlesrpregister}>
                                <img src="images/SalesPartnerIcon.png" alt="Sales Partner Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleemployeinfo}>
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                        
                        </div>

                        <div className="loginrightside">
                            <div className="loginformtitle">Individual Client Registration</div>
                            <div className="formdiv">
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="text"
                                        className={`inputbg4 ${errors.firstName ? 'error-input' : ''}`}
                                        placeholder="First Name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.firstName && <div className="error">{errors.firstName}</div>}

                                    <input
                                        type="text"
                                        className="inputbg4"
                                        placeholder="Middle Name"
                                        name="middleName"
                                        value={formData.middleName}
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        className={`inputbg5 ${errors.lastName ? 'error-input' : ''}`}
                                        placeholder="Last Name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {/* {errors.lastName && <div className="error">{errors.lastName}</div>} */}

                                    <input
                                        type="tel"
                                        className={`inputbg4 ${errors.cellNo ? 'error-input' : ''}`}
                                        placeholder="Cell No"
                                        name="cellNo"
                                        value={formData.cellNo}
                                        onChange={handleInputChange}
                                        required
                                        style={{ borderTop: errors.cellNo ? 'none' : '1px solid #ccc' }} // Remove top border if there's an error
                                    />
                                    {errors.cellNo && <div className="error">{errors.cellNo}</div>}

                                    <input
                                        type="text"
                                        className="inputbg4"
                                        placeholder="Passport/ID No"
                                        name="passportIdNo"
                                        value={formData.passportIdNo}
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        className={`inputbg5 ${errors.nationality ? 'error-input' : ''}`}
                                        placeholder="Nationality"
                                        name="nationality"
                                        value={formData.nationality}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.nationality && <div className="error">{errors.nationality}</div>}

                                    <select className="inputbg4" required>
                                            <option>Gender</option>
                                            <option style={{ color: 'black' }}>Male</option>
                                            <option style={{ color: 'black' }}>Female</option>
                                        </select>
                                    {errors.gender && <div className="error">{errors.gender}</div>}

                                    <input
                                        type="text"
                                        className="inputbg4"
                                        placeholder="Profession"
                                        name="profession"
                                        value={formData.profession}
                                        onChange={handleInputChange}
                                    />

                                    <select
                                        className="select1"
                                        name="registrationPurpose"
                                        value={formData.registrationPurpose}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Registration Purpose</option>
                                        <option value="Option 1">Option 1</option>
                                    </select>

                                    <input
                                        type="email"
                                        className={`inputbg7 ${errors.email ? 'error-input' : ''}`}
                                        placeholder="Email Address"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {/* {errors.email && <div className="error">{errors.email}</div>} */}

                                    <input
                                        type="text"
                                        className="inputbg8"
                                        placeholder="Floor No"
                                        name="floorNo"
                                        value={formData.floorNo}
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        className="inputbg8"
                                        placeholder="Flat No"
                                        name="flatNo"
                                        value={formData.flatNo}
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        className="inputbg8"
                                        placeholder="Street"
                                        name="street"
                                        value={formData.street}
                                        onChange={handleInputChange}
                                    />

                                    <input
                                        type="text"
                                        className={`inputbg7 ${errors.residentialAddress ? 'error-input' : ''}`}
                                        placeholder="Residential Address*"
                                        name="residentialAddress"
                                        value={formData.residentialAddress}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.residentialAddress && <div className="error">{errors.residentialAddress}</div>}

                                    <select
                                        className="inputbg8"
                                        name="stayCountry"
                                        value={formData.stayCountry}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Stay Country</option>
                                        <option value="Option 2">Option 2</option>
                                    </select>

                                    <select
                                        className="inputbg8"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">State</option>
                                        <option value="Option 2">Option 2</option>
                                    </select>

                                    <input
                                        type="text"
                                        className="inputbg8"
                                        placeholder="Postal Code"
                                        name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleInputChange}
                                    />

                                    <div className="indbox">
                                        <div className="companytermheading">Terms & Conditions Agreement*</div>
                                        <div className="companytext2">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry...
                                            </p>
                                        </div>

                                        <div className="termagree">
                                            <input
                                                type="checkbox"
                                                name="termsAgreed"
                                                checked={formData.termsAgreed}
                                                onChange={handleInputChange}
                                            />
                                            I Agree with Terms & Conditions*
                                        </div>
                                        {/* {errors.termsAgreed && <div className="error">{errors.termsAgreed}</div>} */}

                                        <div>
                                            <button type="submit" className="sub1">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* Social media icon div */}
                    <div className="icondiv2">
                        <button><img src="images/Support.png" alt="Support Icon" /></button>
                        <button><img src="images/Helpline.png" alt="Helpline Icon" /></button>
                        <button><img src="images/Complaint.png" alt="Complaint Icon" /></button>
                        <button><img src="images/Update.png" alt="Update Icon" /></button>
                        <button><img src="images/Tiktok.png" alt="TikTok Icon" /></button>
                        <button><img src="images/FB.png" alt="Facebook Icon" /></button>
                        <button><img src="images/YOutube.png" alt="YouTube Icon" /></button>
                        <button><img src="images/Whatsapp.png" alt="WhatsApp Icon" /></button>
                        <button><img src="images/Twitter.png" alt="Twitter Icon" /></button>
                        <button><img src="images/Insta.png" alt="Instagram Icon" /></button>
                        <button><img src="images/Linkedin.png" alt="LinkedIn Icon" /></button>
                        <button><img src="images/Pinterest.png" alt="Pinterest Icon" /></button>
                        <button><img src="images/Google Plus.png" alt="Google Plus Icon" /></button>
                        <button><img src="images/Reddit.png" alt="Reddit Icon" /></button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndRegister;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const InvestorRegister = () => {
    const navigate = useNavigate();
    const [showTerms, setShowTerms] = useState(false); 
    const [showForm, setShowForm] = useState(true); 

    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        cellNo: '',
        passportId: '',
        nationality: '',
        gender: '',
        profession: '',
        registrationPurpose: '',
        companyBusinessName: '',
        typeOfInvestment: '',
        approximateAmount: '',
        areaOfInvestment: '',
        birthday: '',
        officeAddress: '',
        countryOfResidence: '',
        state: '',
        postalCode: ''
    });

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    
        // Remove the error for this field if it is filled
        if (value.trim()) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name];
                return updatedErrors;
            });
        }
    };
    

    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = true;
        if (!formData.lastName) newErrors.lastName = true;
        if (!formData.cellNo) newErrors.cellNo = true;
        if (!formData.passportId) newErrors.passportId = true;
        if (!formData.nationality) newErrors.nationality = true;
        if (!formData.gender) newErrors.gender = true;
        if (!formData.profession) newErrors.profession = true;
        if (!formData.registrationPurpose) newErrors.registrationPurpose = true;
        if (!formData.companyBusinessName) newErrors.companyBusinessName = true;
        if (!formData.typeOfInvestment) newErrors.typeOfInvestment = true;
        if (!formData.approximateAmount) newErrors.approximateAmount = true;
        if (!formData.areaOfInvestment) newErrors.areaOfInvestment = true;
        if (!formData.officeAddress) newErrors.officeAddress = true;
        if (!formData.countryOfResidence) newErrors.countryOfResidence = true;
        if (!formData.state) newErrors.state = true;
        if (!formData.postalCode) newErrors.postalCode = true;
        return newErrors;
    };

    const handleTC = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setShowTerms(true);
            setShowForm(false);
        }
    };

    const handleLandingPage = () => {
        navigate('/');
    };
 

    const handleCRegister = (e) => {
        navigate('/cregister')
    }

    const handlesrpregister = (e) => {
        navigate('/srpregister')
    }
    const handleIndregister = (e) => {
        navigate('/indregister')
    }
    const handleInvestorregistor = (e) => {
        navigate('/investregister')
    }

    const handleemployeinfo = (e) =>{
        navigate('/employeinfo')
    }


    return (
        <>
            <div className="header">
                <button className='btnicon'>
                    <div className="logo2" onClick={handleLandingPage}>
                        <img src="images/BusinessWindow.png" alt="Business Logo" />
                    </div>
                </button>
                <div className="topicons">
                    <button className="setting2"><img src="images/Setting.png" alt="Settings" /></button>
                    <button className="phone2"><img src="images/Phone.png" alt="Phone" /></button>
                </div>
            </div>

            <div className="width1">
                <div className="maindiv">
                    <div className="searchbar2">
                        <form>
                            <input type="submit" className="submitbtn" />
                            <input type="text" placeholder="Search here Business Windows" className="searchfield" />
                            <input type="button" className="voicebtn" />
                        </form>
                    </div>

                    <div className="glassdiv2">
                    <div className="loginleftside">
                        <div className="loginleftsidetext">Select Field</div>
                            <button className="loginleftsidebtn" onClick={handleIndregister}>
                                <img src="images/personalInfo.png" alt="Personal Info Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleCRegister}>
                                <img src="images/CompanyRegistrationIcon.png" alt="Company Registration Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleInvestorregistor}>
                                <img src="images/InvestorsIcon.png" alt="Investors Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handlesrpregister}>
                                <img src="images/SalesPartnerIcon.png" alt="Sales Partner Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn" onClick={handleemployeinfo}>
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                            <button className="loginleftsidebtn">
                                <img src="images/Login Icon .png" alt="Login Icon" height="25" width="25" />
                            </button>
                        
                        </div>

                        {showForm && (
                            
                            <div className="loginrightside">
                                <div className="loginformtitle">Investors Registration</div>
                                <div className="formdiv">
                                    <form onSubmit={handleTC}>
                                        <input 
                                            type="text" 
                                            name="firstName" 
                                            className={`inputbg4 ${errors.firstName ? 'error-border' : ''}`} 
                                            placeholder="First Name" 
                                            value={formData.firstName} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="middleName" 
                                            className="inputbg4" 
                                            placeholder="Middle Name" 
                                            value={formData.middleName} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="lastName" 
                                            className={`inputbg5 ${errors.lastName ? 'error-border' : ''}`} 
                                            placeholder="Last Name" 
                                            value={formData.lastName} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="cellNo" 
                                            className={`inputbg4 ${errors.cellNo ? 'error-border' : ''}`} 
                                            placeholder="Cell No" 
                                            value={formData.cellNo} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="passportId" 
                                            className={`inputbg4 ${errors.passportId ? 'error-border' : ''}`} 
                                            placeholder="Passport/ID No" 
                                            value={formData.passportId} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="nationality" 
                                            className={`inputbg5 ${errors.nationality ? 'error-border' : ''}`} 
                                            placeholder="Nationality" 
                                            value={formData.nationality} 
                                            onChange={handleInputChange} 
                                        />
                                        <select 
                                            name="gender" 
                                            className={`inputbg4 ${errors.gender ? 'error-border' : ''}`} 
                                            value={formData.gender} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        <input 
                                            type="text" 
                                            name="profession" 
                                            className={`inputbg4 ${errors.profession ? 'error-border' : ''}`} 
                                            placeholder="Profession" 
                                            value={formData.profession} 
                                            onChange={handleInputChange} 
                                        />
                                        <select 
                                            name="registrationPurpose" 
                                            className={`select1 ${errors.registrationPurpose ? 'error-border' : ''}`} 
                                            value={formData.registrationPurpose} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Registration Purpose</option>
                                            <option value="Option 1">Option 1</option>
                                            <option value="Option 2">Option 2</option>
                                        </select>
                                        <input 
                                            type="text" 
                                            name="companyBusinessName" 
                                            className={`inputbg6 ${errors.companyBusinessName ? 'error-border' : ''}`} 
                                            placeholder="Company Business Name" 
                                            value={formData.companyBusinessName} 
                                            onChange={handleInputChange} 
                                        />
                                        <select 
                                            name="typeOfInvestment" 
                                            className={`select2 ${errors.typeOfInvestment ? 'error-border' : ''}`} 
                                            value={formData.typeOfInvestment} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Type of Investment</option>
                                            <option value="Option 1">Option 1</option>
                                            <option value="Option 2">Option 2</option>
                                        </select>
                                        <select 
                                            name="approximateAmount" 
                                            className={`inputbg7 ${errors.approximateAmount ? 'error-border' : ''}`} 
                                            value={formData.approximateAmount} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Approximately Amount</option>
                                            <option value="Option 1">Option 1</option>
                                            <option value="Option 2">Option 2</option>
                                        </select>
                                        <select 
                                            name="areaOfInvestment" 
                                            className={`select2 ${errors.areaOfInvestment ? 'error-border' : ''}`} 
                                            value={formData.areaOfInvestment} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Area of Investment</option>
                                            <option value="Option 1">Option 1</option>
                                            <option value="Option 2">Option 2</option>
                                        </select>
                                        <input 
                                            type="date" 
                                            name="birthday" 
                                            className="inputbg7" 
                                            value={formData.birthday} 
                                            onChange={handleInputChange} 
                                        />
                                        <input 
                                            type="text" 
                                            name="officeAddress" 
                                            className={`inputbg7 ${errors.officeAddress ? 'error-border' : ''}`} 
                                            placeholder="Office Address" 
                                            value={formData.officeAddress} 
                                            onChange={handleInputChange} 
                                        />
                                        <select 
                                            name="countryOfResidence" 
                                            className={`inputbg8 ${errors.countryOfResidence ? 'error-border' : ''}`} 
                                            value={formData.countryOfResidence} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Country of Residence</option>
                                            <option value="Type 1">Type 1</option>
                                            <option value="Type 2">Type 2</option>
                                        </select>
                                        <select 
                                            name="state" 
                                            className={`inputbg8 ${errors.state ? 'error-border' : ''}`} 
                                            value={formData.state} 
                                            onChange={handleInputChange}
                                        >
                                            <option value="">State</option>
                                            <option value="Option 2">Option 2</option>
                                        </select>
                                        <input 
                                            type="text" 
                                            name="postalCode" 
                                            className={`inputbg8 ${errors.postalCode ? 'error-border' : ''}`} 
                                            placeholder="Postal Code" 
                                            value={formData.postalCode} 
                                            onChange={handleInputChange} 
                                        />
                                        <div className="clicknext2">
                                            <button onClick={handleTC} type="submit">Click Next to Proceed* <img src="images/ClickNextToProced.png" alt="Next" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}

                        {showTerms && (
                            <div className="loginrightside">
                                <div className="loginformtitle">Terms & Conditions</div>
                                <div className="formdiv">
                                    <div className="companytermheading">Terms & Conditions Agreement*</div>
                                    <div className="companytext">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                    </div>
                                    <div className="termagree">
                                        <label>
                                            <input type="checkbox" required /> I Agree with Terms & Conditions*
                                        </label>
                                    </div>
                                    <div>
                                        <button className="sub1">Submit</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvestorRegister;

import React from "react";
import '../styles/styles.css'
import { useNavigate } from "react-router-dom";
const Vehicle = () => {
  const navigate =useNavigate();

  const handlevehicalregister = () =>{
    navigate('/vehicalRegister');
  }
  return (
    
    <>
    
      <div className="header">
        <div className="logo2">
          <img src="images/BusinessWindow.png" alt="Business Window" />
        </div>
        <div className="topicons">
          <button className="setting2">
            <img src="images/Setting.png" alt="Setting" />
          </button>
          <button className="phone2">
            <img src="images/Phone.png" alt="Phone" />
          </button>
        </div>
      </div>

      <div className="width1">
        <div className="maindiv">
          <div className="searchbar2">
            <form>
              <input type="submit" className="submitbtn" />
              <input
                type="text"
                placeholder="Search here Business Windows"
                className="searchfield"
              />
              <input type="button" className="voicebtn" />
            </form>
          </div>

          <div className="glassdiv2">
            <div className="topdiv">
              <div className="vehloginformtitle">Vehicle Registration System</div>
              <div className="editinfo2">
                <button>
                  Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" />
                </button>
              </div>
            </div>

            <div className="loginleftside2">
              <div className="loginleftsidetext">Select Field</div>
              {[...Array(7)].map((_, idx) => (
                <div className="infobtn" key={idx}>
                  <button className="loginleftsidebtn3">
                    <img
                      src="images/Login Icon .png"
                      height="15"
                      width="15"
                      alt="Login Icon"
                    />
                  </button>
                  Education
                </div>
              ))}
            </div>

            <div className="loginrightside2">
              <div className="formdiv1">
                <select className="inputbg11">
                  <option>Vehicle Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
                <input
                  type="text"
                  className="inputbg11"
                  placeholder="Registration No"
                />

                <select className="inputbg11">
                  <option>Insurance Status</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Insurance Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Registered Country</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>State</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>City</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <input
                  type="date"
                  className="inputbg11"
                  placeholder="Registration Expiry"
                />

                <select className="inputbg11">
                  <option>Warranty Status</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Model No</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Made In</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Brand Name</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Origin</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Registration Status</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Drive Hand</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Accident History</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <input
                  type="text"
                  className="inputbg11"
                  placeholder="Chases No"
                />

                <select className="inputbg11">
                  <option>Chases Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Chases Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <input type="text" className="inputbg11" placeholder="Body No" />

                <select className="inputbg11">
                  <option>Body Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Body Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Body Paint Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Engine No</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Engine Type</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <select className="inputbg11">
                  <option>Engine Condition</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>

                <div onClick={handlevehicalregister} click className="clicknext" style={{ marginTop: "5px" }}>
                  <button>
                    Click Next to Proceed{" "}
                    <img
                      src="images/ClickNextToProced.png"
                      alt="Next to Proceed"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="icondiv2">
            {[
              "Support",
              "Helpline",
              "Complaint",
              "Update",
              "Tiktok",
              "FB",
              "YOutube",
              "Whatsapp",
              "Twitter",
              "Insta",
              "Linkedin",
              "Pinterest",
              "Google Plus",
              "Reddit",
            ].map((icon, idx) => (
              <button key={idx}>
                <img src={`images/${icon}.png`} alt={icon} />
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Vehicle;

import React from 'react';
import '../styles/styles.css'; 

const VehicleSubmit = () => {
  return (
    <div className="header">
      <div className="logo2">
        <img src="images/BusinessWindow.png" alt="Business Window" />
      </div>
      <div className="topicons">
        <button className="setting2">
          <img src="images/Setting.png" alt="Settings" />
        </button>
        <button className="phone2">
          <img src="images/Phone.png" alt="Phone" />
        </button>
      </div>

      <div className="width1">
        <div className="maindiv">
          <div className="searchbar2">
            <form>
              <input type="submit" className="submitbtn" value="Submit" />
              <input
                type="text"
                placeholder="Search here Business Windows"
                className="searchfield"
              />
              <inphuut type="button" className="voicebtn" value="Voice Search" />
            </form>
          </div>

          <div className="glassdiv2">
            <div className="topdiv">
              <div className="vehloginformtitle">Vehicle Registration System</div>
              <div className="editinfo2">
                <button>
                  Edit Info <img src="images/EditInfoIcon.png" alt="Edit Info" />
                </button>
              </div>
            </div>

            <div className="loginleftside2">
              <div className="loginleftsidetext">Select Field</div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon .png"
                    alt="Icon"
                    height="15"
                    width="15"
                  />
                </button>
                Personal Info
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon .png"
                    alt="Icon"
                    height="15"
                    width="15"
                  />
                </button>
                Education
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon. png"
                    alt="Icon"
                    height="15"
                    width="15"
                  />
                </button>
                Education
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon. png"
                    alt="Icon"
                    height="15"
                    width="15"
                  />
                </button>
                Education
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon .png"
                    alt="Icon"
                    height="15"
                    width="15"
                  />
                </button>
                Education
              </div>
              <div className="infobtn">
                <button className="loginleftsidebtn3">
                  <img
                    src="images/Login Icon. png"
                    alt="Icon"
                    height="15"
                    width="15"
                  />
                </button>
                Education
              </div>
              
            </div>

            <div className="loginrightside2">
              <div className="formdiv1">
                <div className="companytermheading">
                  Terms & Conditions Agreement*
                </div>
                <div className="companytext">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>

                <div className="termagree">
                  <input type="checkbox" id="terms" />
                  <label htmlFor="terms"> I Agree with Terms & Conditions*</label>
                </div>
                <div>
                  <button className="sub1">Submit</button>
                </div>
              </div>
            </div>
          </div>

          <div className="icondiv2">
            <button>
              <img src="images/Support.png" alt="Support" />
            </button>
            <button>
              <img src="images/Helpline.png" alt="Helpline" />
            </button>
            <button>
              <img src="images/Complaint.png" alt="Complaint" />
            </button>
            <button>
              <img src="images/Update.png" alt="Update" />
            </button>
            <button>
              <img src="images/Tiktok.png" alt="Tiktok" />
            </button>
            <button>
              <img src="images/FB.png" alt="Facebook" />
            </button>
            <button>
              <img src="images/Youtube.png" alt="Youtube" />
            </button>
            <button>
              <img src="images/Whatsapp.png" alt="Whatsapp" />
            </button>
            <button>
              <img src="images/Twitter.png" alt="Twitter" />
            </button>
            <button>
              <img src="images/Insta.png" alt="Instagram" />
            </button>
            <button>
              <img src="images/Linkedin.png" alt="LinkedIn" />
            </button>
            <button>
              <img src="images/Pinterest.png" alt="Pinterest" />
            </button>
            <button>
              <img src="images/Google Plus.png" alt="Google Plus" />
            </button>
            <button>
              <img src="images/Reddit.png" alt="Reddit" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleSubmit;
